import { ApgUser } from "@/domain/account.interface";
import { apgGraphQL } from "./apgApi"
import { operationEditProfile, operationProfileEvent, operationProfileFan, operationProfileFollow, operationProfileGame } from "./graph-queries";
import { RequestError } from "./general.endpoints";
import { UserGame, UserMenu } from "@/domain/account/profile.interface";
import { SelectOption } from '@apg.gg/core/lib/Select';
import { convertEventTypesToOptions } from "@/lib/utils/generals";
import { generalOperations } from "./graph-general-queries";
import { operationProfileFollows } from "./graph-profile-queries";
import { InfinitePaginationResponse } from "@/domain/general.interface";
import { UserFollowType } from "@/domain/profile.interface";
import { RawDraftContentState } from "draft-js";
import { operationFollows } from "./follows/queries";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

export interface EditProfileSetProps<T> {
  userId?: number;
  token: string;
  data: T;
}

const getUserMenu = async ({ userId }: any): Promise<UserMenu[]> => {
  const { data, errors } = await apgGraphQL(
    generalOperations,
    'GetUserMenu',
    {
      "userid": userId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.userMenu;
}

const updateProfileAbout = async ({ data: userData, userId, token }: EditProfileSetProps<{ about: string, aboutRich: RawDraftContentState }>): Promise<ApgUser> => {
  const { data, errors } = await apgGraphQL(
    operationEditProfile,
    'EditProfileAbout',
    {
      "_set": {
        "aboutRich": userData.aboutRich,
        "about": userData.about
      },
      "pk_columns": {
        "id": userId
      }
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.updatedUser;
}

const getUserGames = async ({ userId, token }: any): Promise<UserGame[]> => {
  const showOnlyActive = !token ? { "isActive": { "_eq": true } } : {};

  const { data, errors } = await apgGraphQL(
    operationProfileGame,
    'GetUserGames',
    {
      "where": {
        "userId": { "_eq": userId },
        ...showOnlyActive
      }
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.userGames;
}

const insertUserGame = async ({ data: userGame, token }: EditProfileSetProps<Partial<UserGame>>): Promise<ApgUser> => {
  const { data, errors } = await apgGraphQL(
    operationProfileGame,
    'InsertUserGame',
    {
      "object": userGame
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.insertUserGame;
}

const updateUserGame = async ({ data: userGame, userId, token }: EditProfileSetProps<Partial<UserGame>>): Promise<ApgUser> => {
  const { data, errors } = await apgGraphQL(
    operationProfileGame,
    'UpdateUserGame',
    {
      "_set": userGame,
      "id": userId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.updatedUserGame;
}

const getEventTypes = async (): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationProfileEvent,
    'GetEventTypes'
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return convertEventTypesToOptions(data.eventTypes);
}

const getFollowUser = async ({
  userId,
  followingId
}: {
  userId: number;
  followingId: number;
}): Promise<boolean> => {
  const { data, errors } = await apgGraphQL(
    operationProfileFollow,
    'GetFollowUser',
    {
      "userId": userId,
      "followingId": followingId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.userFollow[0] ? true : false;
}

const follow = async ({
  followingId,
  token
}: {
  followingId: number;
  token: string;
}): Promise<boolean> => {
  const { data, errors } = await apgGraphQL(
    operationProfileFollow,
    'Follow',
    {
      "followingId": followingId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.follow.id ? true : false;
}

const unfollow = async ({
  userId,
  followingId,
  token
}: {
  userId: number;
  followingId: number;
  token: string;
}): Promise<boolean> => {
  const { data, errors } = await apgGraphQL(
    operationProfileFollow,
    'Unfollow',
    {
      "userId": userId,
      "followingId": followingId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.unfollow.affectedRows > 0;
}

const getUserFollowers = async ({
  userId,
  followId,
  limit = 30, 
  page = 1
}: {
  userId: number,
  followId: number, 
  limit?: number, 
  page?: number
}): Promise<InfinitePaginationResponse<UserFollowType>> => {      
  const { data, errors } = await apgGraphQL(
    operationFollows,
    'GetUserFollowers',
    {
      "userId": userId,
      "followId": followId,
      "limit": limit,
      "offset": (page - 1) * limit
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.total.count;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  const users = data.userFollowers.map((item: any) => {
    return {
      ...item.user,
      isFollowed: item.user.userFollowers_aggregate.aggregate.count > 0,
      isFollower: item.user.userFollowing_aggregate.aggregate.count > 0
    }
  });

  return {
    items: users,
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  }
}

const getUserFollowings = async ({
  userId,
  followId,
  limit = 30, 
  page = 1
}: {
  userId: number,
  followId: number, 
  limit?: number, 
  page?: number
}): Promise<InfinitePaginationResponse<UserFollowType>> => {      
  const { data, errors } = await apgGraphQL(
    operationFollows,
    'GetUserFollowings',
    {
      "userId": userId,
      "followId": followId,
      "limit": limit,
      "offset": (page - 1) * limit
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.total.count;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  const users = data.userFollowings.map((item: any) => {
    return {
      ...item.following,
      isFollowed: item.following.userFollowers_aggregate.aggregate.count > 0,
      isFollower: item.following.userFollowing_aggregate.aggregate.count > 0
    }
  });

  return {
    items: users,
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  }
}

const getFanUser = async ({
  fanUserId,
  idolUserId
}: {
  fanUserId: number;
  idolUserId: number;
}): Promise<boolean> => {
  const { data, errors } = await apgGraphQL(
    operationProfileFan,
    'GetFanUser',
    {
      "fanUserId": fanUserId,
      "idolUserId": idolUserId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.userFan[0] ? true : false;
}

const fan = async ({
  idolUserId,
  token
}: {
  idolUserId: number;
  token: string;
}): Promise<boolean> => {
  const { data, errors } = await apgGraphQL(
    operationProfileFan,
    'Fan',
    {
      "idolUserId": idolUserId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.follow.id ? true : false;
}

const unfan = async ({
  fanUserId,
  idolUserId,
  token
}: {
  fanUserId: number;
  idolUserId: number;
  token: string;
}): Promise<boolean> => {
  const { data, errors } = await apgGraphQL(
    operationProfileFan,
    'Unfan',
    {
      "fanUserId": fanUserId,
      "idolUserId": idolUserId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.unfollow.affectedRows > 0;
}

const getUserFans = async ({
  userId,
  followId,
  limit = 30, 
  page = 1
}: {
  userId: number,
  followId: number, 
  limit?: number, 
  page?: number
}): Promise<InfinitePaginationResponse<UserFollowType>> => {      
  const { data, errors } = await apgGraphQL(
    operationProfileFollows,
    'GetUserFollowers',
    {
      "userId": userId,
      "followId": followId,
      "limit": limit,
      "offset": (page - 1) * limit
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.userFollowers.aggregate.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.userFollowers.items,
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  }
}

const getUserIdols = async ({
  userId,
  followId,
  limit = 30, 
  page = 1
}: {
  userId: number,
  followId: number, 
  limit?: number, 
  page?: number
}): Promise<InfinitePaginationResponse<UserFollowType>> => {      
  const { data, errors } = await apgGraphQL(
    operationProfileFollows,
    'GetUserFollowings',
    {
      "userId": userId,
      "followId": followId,
      "limit": limit,
      "offset": (page - 1) * limit
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.userFollowings.aggregate.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.userFollowings.items,
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  }
}

export const ProfileEndpoints = {
  getUserMenu,
  updateProfileAbout,
  getUserGames,
  insertUserGame,
  updateUserGame,
  getEventTypes,
  getFollowUser,
  follow,
  unfollow,
  getUserFollowers,
  getUserFollowings,
  getFanUser,
  fan,
  unfan,
  getUserFans,
  getUserIdols
}
