// FollowButton.tsx
import { FC, useState, MouseEvent } from "react";
import { ProfileEndpoints } from "@/graph/profile.endpoints";
import { toast } from "react-toastify";
import Button from "@apg.gg/core/lib/Button";
import Modal from "@apg.gg/core/lib/Modal";
import { useTranslations } from "next-intl";
import { cn } from "@apg.gg/core/lib/cn";
import { useRouter } from "next-nprogress-bar";
import { useParams } from "next/navigation";
import useAuthStore from "@/store/auth.store";

interface FollowButtonProps {
  isFollowing: boolean;
  username: string;
  followingId: number;
}

const FollowButton: FC<FollowButtonProps> = ({ isFollowing, username, followingId }) => {
  const router = useRouter();
  const { locale } = useParams();
  const { userId, authToken } = useAuthStore();
  const t = useTranslations('Profile');
  const [isHovered, setIsHovered] = useState(false);
  const [isFollowed, setIsFollowed] = useState(isFollowing);
  const [showUnfollowModal, setShowUnfollowModal] = useState(false);

  const onFollow = async () => {
    try {
      await ProfileEndpoints.follow({
        followingId: followingId as number,
        token: authToken
      });

      toast.success(`${t('followed-successfully', { username })}`);
      setIsFollowed(true);
    } catch (error) {
      toast.error(`${t('followed-failed', { username })}`);
    }
  }

  const onUnfollow = async () => {
    try {
      await ProfileEndpoints.unfollow({
        userId: userId as number,
        followingId: followingId as number,
        token: authToken
      });
      toast.success(`${t('unfollowed-successfully', { username })}`);
      setIsFollowed(false);
    } catch (error) {
      toast.error(`${t('unfollowed-failed', { username })}`);
    }
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    console.log("event", event);
    console.log("event.preventDefault()", event.preventDefault());
    console.log("event.stopPropagation()", event.stopPropagation());

    event.preventDefault()
    event.stopPropagation();

    if (isFollowed) {
      setShowUnfollowModal(true);
    } else {
      if (authToken) {
        onFollow();
      } else {
        router.push(`/${locale}/auth/login`);
      }
    }
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="follow-button ml-auto"
      >
        <Button
          fontSize="xs"
          onClick={handleClick}
          className={cn(
            {
              "hover:bg-red w-28": isFollowed
            }
          )}
        >
          {isFollowed ? (isHovered ? t('button-unfollow') : t('button-following')) : t('button-follow')}
        </Button>
      </div>

      {/* Unfollow Modal */}
      {showUnfollowModal ? (
        <Modal
          prefixCls="apg-modal"
          isOpen={showUnfollowModal}
          onClose={() => setShowUnfollowModal(false)}
          title={t('unfollow-modal-title', {
            username: username
          })}
          content={t('unfollow-modal-description', {
            username: username
          })}
          confirmText={t('button-unfollow')}
          cancelText={t('button-cancel')}
          onConfirm={onUnfollow}
          centered
          classNames={{
            wrapper: "max-w-xs",
            header: "text-title-lg",
            content: "text-body-lg text-white-200"
          }}
        />
      ) : null}
    </>
  );
}

export default FollowButton;
